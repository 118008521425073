.stat{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circle-stat {
    display: flex;
    border: 1.5px solid #FBFF12;
    border-radius: 10000px;
    padding: 25px;
    color: #FBFF12;
    font-weight: 700;
    font-size: 24pt;
    width: 75px;
    height: 75px;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.stat-label {
    color: #FBFF12;
    font-weight: 700;
    font-size: 10pt;
    padding-top: 8px;
    user-select: none;
    text-align: center;
}