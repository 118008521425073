.price-card {
    border: 3px solid;
    border-radius: 10px;
    padding: 15px;
    margin: 15px 0px;
    position: relative;
}

.col {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.price {
    margin-top: 10px;
    color: white;
    font-weight: 600;
}

.description {
    margin-top: 3px;
    color: white;
    font-weight: 300;
    line-height: 155%;
}

.tag {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #FF206E;
    color: white;
    font-weight: 700;
    padding: 2px 8px;
    border: none;
    border-radius: 100px;
}