.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Inter" sans-serif;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
  background: #0C0F0A;
  color: white;
}

.content-window {
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 768px;
  min-width: 340px;
}

.h-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pfp {
  width: min(250px, 40vw);
  border-radius: 500px;
}

.head {
  height: 125px;
}

.yellow {
  color: #FBFF12;
}

.display-grid {
  display: grid;
  padding: 15px;
}

.big-number {
  font-size: 48pt;
  font-weight: 600;
}

.inline-link {
  text-decoration: underline;
}

p {
  line-height: 165%;
}


$grid__bp--md: 768;
$grid__cols: 12;

.container {
  max-width: $grid__bp--md * 1px;
  margin: 0 auto;
  &--fluid {
    margin: 0;
    max-width: 100%;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  @for $i from 1 through $grid__cols {
    &__col-sm-#{$i} {
      flex-basis: (100 / ($grid__cols / $i) ) * 1%;
    }
  }
  @media screen and (min-width: $grid__bp--md * 1px) {
    @for $i from 1 through $grid__cols {
      &__col-md-#{$i} {
        flex-basis: (100 / ($grid__cols / $i) ) * 1%;
      }
    }
  }
}

//Styling of grid for demostration purposes
.container {
  padding: 0px;
  margin: 50px;
  box-sizing: border-box;
  @at-root {
    [class*='container__col-'] {
      width: 10px;
      height: 150px;
      margin: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
}

a {
  text-decoration: none;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action-button {
  padding: 10px 18px;
  margin: 15px;
  margin-top: 0;
  color: black;
  font-size: min(16pt, 3.0vw);
  font-weight: 700;
  background-color: #FBFF12;
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  width: 70%;
  min-width: 260px;
  align-self: center;
  transform: scale(1.0);
  transition-duration: 505ms;
}

.action-button:hover {
  transform: scale(1.03);
  transition-duration: 505ms;
}


.major {
  font-size: 72pt;
}

.submajor {
  font-size: 14pt;
}

.paragraph {
  line-height: 155%;
}

.glitch {
  font: {
    size: 4.125em;
    family: 'Inter', sans-serif;
    weight: 700;
  }
  user-select: none;
  text-decoration: none;
  transform: translate(0%, 0%);
  margin: 0;
  color: #FBFF12;

  &:before, &:after {
    display: block;
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: .8;
  } &:after {
    color: #FF206E;
    z-index: -2;
  } &:before {
    color: #41EAD4;
    z-index: -1;
  }
  &:hover {
    &:before {
      animation: glitch .5s cubic-bezier(.25, .46, .45, .94) both 2
    }
    &:after {
      animation: glitch .5s cubic-bezier(.25, .46, .45, .94) reverse both 2
    }
  }
}

@keyframes glitch {
  0% {
    transform: translate(0)
  }
  20% {
    transform: translate(-5px, 5px)
  }
  40% {
    transform: translate(-5px, -5px)
  }
  60% {
    transform: translate(5px, 5px)
  }
  80% {
    transform: translate(5px, -5px)
  }
  to {
    transform: translate(0)
  }
}