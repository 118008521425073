.label-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 768px;
}

.lc-top {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 100%;
}

.platform {
    color: white;
    font-weight: 700;
}

.handle {
    color: #FBFF12;
    text-decoration: none;
    font-weight: 600;
    margin-left: 10px;
}

.handle:hover {
    color: #FF206E;
}

.lc-box {
    color: white;
    border: 2px solid;
    border-color: #404040;
    border-radius: 5px;
}